import {
  Dropdown, Field, Item, Menu, Select
} from "@zendeskgarden/react-dropdowns";
import {
  Field as ToggleField, Label, Toggle
} from "@zendeskgarden/react-forms";
import { ThemeProvider } from '@zendeskgarden/react-theming';
import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Link, withRouter } from "react-router-dom";
import amountIcon from "../../../images/icons/amount-icon.svg";
import editIcon from "../../../images/icons/edit-icon.svg";
import xIcon from "../../../images/icons/icon_x_red.png";
import magnifierIcon from "../../../images/icons/icon_magnifier.png";
import service from "../../../services/api.service";
import OpportunityService from "../../../services/opportunity.service";
import { t } from "../../../translations/translation.service";
import { theme } from '../../../util/colorButton';
import Loading from "../../loading";
import CommentComponent from "./comment";
import "./index.css";
import PeriodsComponent from "./periods";
import moment from "moment";
import UserService from "../../../services/user.service";


const labels = [
  { label: "Ativar no Kanban", value: "1" },
  { label: "Ativar no Kanban", value: "2" },
];

const publicStatus = [
  {
    name: "Exibir no Mural",
    status: true
  },
  {
    name: "Exibir no mural",
    status: false
  }
];

class CardMajor extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.handleChangeAmount = this.handleChangeAmount.bind(this);
    this.handleChangeDefeats = this.handleChangeDefeats.bind(this);
  }

  state = {
    squads: [],
    responsables: [],
    selectedSquad: {} as any,
    selectedLabel: {} as any,
    selectedResp: {} as any,
    loading: true,
    opportunity: {} as any,
    amount: undefined as any,
    defeats: 0,
    victories: 0,
    publicValue: {} as any
  };

  async componentDidMount() {
    const id = this.props.match.params.id;
    const opportunity = (await OpportunityService.getOpportunityLiteKanban(id))
      .data;
    const dates = await OpportunityService.getOpportunityDates(id);
    opportunity.periods = dates.data;
    const squads = await service.get("squad");
    let responsables = await UserService.getResponsables();
    const selectedLabel = labels.filter(
      (label) => label.value === opportunity.label
    );
    const publicValue = publicStatus.filter(
      element => element.status === opportunity.isPublic
    );

    this.setState({
      opportunity: opportunity,
      loading: false,
      squads: squads.data,
      responsables: (await responsables).data,
      selectedSquad: opportunity.squad,
      selectedResp: opportunity.user,
      selectedLabel: selectedLabel[0],
      publicValue: publicValue[0],
      amount: opportunity.quantidadeVagas,
      victories: opportunity.victories,
      defeats: opportunity.defeats
    });
  }

  async updateSquad(squadId: any, opportunityId: any) {
    let data = { squadId, opportunityId };
    this.setState({ loading: true });
    try {
      await service.post("opportunity/update-squad", data);
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async updateResponsable(userId: any, opportunityId: any) {
    let data = { userId, opportunityId };
    this.setState({ loading: true });
    try {
      await service.post("opportunity/update-responsable", data);
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  }
  
  async updateIsPublic(status: boolean, opportunityId: string, label?: string) {
    let data = { label, status, opportunityId };
    try {
      this.setState({ loading: true });
      await service.put("opportunity/update-public", data);
      if (label === "2") {
        let dates = await service.get(`opportunity/dates/${opportunityId}`);
        this.state.opportunity.periods = dates.data; 
      }
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
    }
  }

  async updateLabel(label: any, opportunityId: any, fase: any) {
    let data = { label, opportunityId, fase };
    try {
      this.setState({ loading: true });
      await service.post("opportunity/update-label", data);
      let dates = await service.get(`opportunity/dates/${opportunityId}`);
      this.state.opportunity.periods = dates.data;
      this.setState({ loading: false });
    } catch (error) {
      console.error(error);
    }
  }

  async updateAmount(amount: any, opportunityId: any) {
    if (amount !== "" && amount !== this.state.amount) {
      if (amount < 0) {
        amount = 0;
      }
      let data = { amount: amount, opportunityId: opportunityId };
      try {
        await service.post("opportunity/update-amount", data);
      } catch (error) {
        console.error(error);
      }
      this.setState({ amount });
    } else {
      this.setState({ amount: this.state.amount });
    }
  }

  async updateDefeats(defeats: any, opportunityId: any) {
    if (defeats !== "" && defeats !== this.state.defeats && defeats >= 0) {
      this.setState({ loading: true });
      const sum = this.state.defeats - defeats;
      this.updateAmount(this.state.amount - sum * -1, opportunityId);
      let data = { defeats: defeats, opportunityId: opportunityId };
      try {
        await service.post("opportunity/update-defeats", data);
        let dates = await service.get(`opportunity/dates/${opportunityId}`);
        this.state.opportunity.periods = dates.data;
      } catch (error) {
        console.error(error);
      }
      this.setState({ defeats, loading: false });
    } else {
      this.setState({ defeats: this.state.defeats });
    }
  }

  handleChangeAmount(event: any) {
    this.updateAmount(event.target.value, this.state.opportunity._id);
  }

  handleChangeDefeats(event: any) {
    this.updateDefeats(event.target.value, this.state.opportunity._id);
  }

  truncate(str: string) {
    return str && str.length > 600 ? str.substring(0, 600) + "..." : str;
  }

  clearOppSquad() {
    this.setState({ selectedSquad: {} });
    this.updateSquad(null, this.state.opportunity._id);
  }

  clearOppResponsible() {
    this.setState({ selectedResp: {} });
    this.updateResponsable(null, this.state.opportunity._id);
  }

  render() {
    let opportunity = this.state.opportunity;
    let qty = [];
    for (let i = 0; i <= 10; i++) {
      qty.push(
        <option style={{ color: "#000" }} key={i} value={i}>
          {i}
        </option>,
      );
    }
    return (
      <div
        className={
          "card border-left-" + opportunity.squad?._id + " shadow py-2 p-4"
        }
      >
        <Loading show={this.state.loading} />
        <div>
          <div
            className={"squad-color-" + opportunity.squad?._id + " u-fs-md mb-1"}
          >
            {opportunity.cliente === ""
              ? "Cliente Não Informado"
              : opportunity.cliente}
            <button
              type="button"
              onClick={this.props.history.goBack}
              className="close"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="d-flex mb-1 mt-1"
            style={{
              fontWeight: "bold",
              fontSize: "0.7rem"
            }}
          >
            <span className="mr-1">
              Status:{" "}
              {this.state.opportunity.isFinished
                ? t("Finalizada")
                : this.state.opportunity.isPublic
                ? t("Publicada")
                : t("Não publicada")}
            </span>
            ||
            <span className="ml-1">{t("Criada em")}: {moment(opportunity.created).format("DD/MM/YYYY")}</span>
          </div>
        </div>
        <div className="d-flex">
          <div className="u-fs-lg">
            <a
              className="formControl d-flex flex-wrap"
              rel="noopener noreferrer"
              target="_blank"
              href={`/opportunity/${this.state.opportunity._id}`}
            >
              {opportunity.titulo}
              <span
                className="ml-2 mt-1 mr-1 d-flex flex-wrap"
                style={{ fontSize: "15px"}}
              >
                <p>ID:{" "}({opportunity.idOpportunity})</p>
                <img src={editIcon} className="ml-1 mb-3" alt=""/>
              </span>
            </a>
          </div>
          <div className="d-flex justify-content-start ml-4 flex-wrap col-sm-5">
            <ThemeProvider theme={theme as any}>
              <ToggleField className="p-1">
                <Toggle
                  checked={
                    this.state.selectedLabel &&
                    this.state.selectedLabel.value === "1"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (this.state.publicValue?.status) return
                    if (e.target.checked) {
                      this.setState({ selectedLabel: labels[0] });
                      this.updateLabel(
                        labels[0].value,
                        opportunity._id,
                        opportunity.fase
                      );
                    } else {
                      this.setState({ selectedLabel: labels[1] });
                      this.updateLabel(
                        labels[1].value,
                        opportunity._id,
                        opportunity.fase
                      );
                    }
                  }}
                >
                  <Label>
                    {t("Ativar no Kanban")}
                  </Label>
                </Toggle>
              </ToggleField>
            </ThemeProvider>
            <ThemeProvider theme={theme as any}>
              <ToggleField className="p-1">
                <Toggle
                  checked={this.state.publicValue.status}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const oldLabel = this.state.selectedLabel.value;
                      this.setState({ publicValue: publicStatus[0], selectedLabel: labels[0] });
                      this.updateIsPublic(publicStatus[0].status, opportunity._id, oldLabel);
                    } else {
                      this.setState({ publicValue: publicStatus[1] });
                      this.updateIsPublic(publicStatus[1].status, opportunity._id);
                    }
                  }}
                >
                  <Label>
                    {t("Exibir no Mural")}
                  </Label>
                </Toggle>
              </ToggleField>
            </ThemeProvider>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-8 col-sm-12 u-fs-sm pr-3">
            <div
              className={` mb-2 title-qty-opp squad-background-${
                opportunity.squad ? opportunity.squad._id : ""
              }`}
            >
              <div className="d-flex" style={{ height: "100%" }}>
                <div className="mt-2" style={{ minWidth: "255px" }}>
                  <img
                    className="m-2 ml-3"
                    src={amountIcon}
                    alt="Quantidade de Vagas"
                  />
                  <span className="u-fs-lg">{t("Posições a preencher")}</span>
                  <input
                    className="input-select-qty ml-2 u-fs-md"
                    value={this.state.amount || 0}
                    onChange={this.handleChangeAmount}
                    type="number"
                    style={{ width: "40px" }}
                  />
                </div>
                <div className="ml-auto mt-2 pt-1">
                  <span className="u-fs-md">{t("Vitória")}(s)</span>
                  <input
                    type="number"
                    style={{ width: "38px" }}
                    className="input-select-qty ml-1 mr-3 u-fs-md"
                    value={this.state.victories || 0}
                    disabled
                  ></input>
                  <span className="u-fs-md ml-1">{t("Derrota")}(s)</span>
                  <input
                    type="number"
                    style={{ width: "38px" }}
                    className="input-select-qty ml-1 mr-3 u-fs-md"
                    value={this.state.defeats || 0}
                    onChange={this.handleChangeDefeats}
                  ></input>
                </div>
              </div>
            </div>
            {ReactHtmlParser(this.truncate(opportunity.detalhes))}
            {/* a corrigir */}
            <CommentComponent opportunity={opportunity}></CommentComponent>
          </div>

          <div className="col-md-4 col-sm-12">
            <div className="row">
              <div className="col-md-12">
                <div className="candidates-counter mb-3 pl-3 pr-3 pb-3 pt-2">
                  <span className="u-fs-sm text-uppercase">{t("Profissionais")}</span>
                  <div className="m-2">
                    <div className="u-fs-sm d-flex hb-divider candidates-link">
                      <div>
                        <Link
                          to={{
                            pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
                            state: "compatibles"
                          }}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Compativeis")}
                          <img src={magnifierIcon} alt="" />
                        </Link>
                      </div>
                      <div className="ml-auto mb-3 u-semibold">
                        {opportunity.quantidadeCompativeis
                          ? opportunity.quantidadeCompativeis
                          : 0}
                      </div>
                    </div>
                    <div className="u-fs-sm d-flex hb-divider candidates-link">
                      <div>
                        <Link
                          to={{
                            pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
                            state: "enrolled"
                          }}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Inscritos")}
                          <img src={magnifierIcon} alt="" />
                        </Link>
                      </div>
                      <div className="ml-auto mb-3 u-semibold">
                        {opportunity.candidatosTriados
                          ? opportunity.candidatosTriados.length
                          : 0}
                      </div>
                    </div>
                    <div className="u-fs-sm d-flex candidates-link">
                      <div>
                        <Link
                          to={{
                            pathname: `/opportunity/${this.state.opportunity._id}/professionals`,
                            state: "candidates"
                          }}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("Candidatos")}
                          <img src={magnifierIcon} alt="" />
                        </Link>
                      </div>
                      <div className="ml-auto mb-3 u-semibold">
                        {opportunity.candidatos
                          ? opportunity.candidatos.length
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="mr-2 u-fs-sm">{t("ESQUADRÃO")}:</label>
                <button 
                  className="cancel-modal-button"
                  onClick={() => this.clearOppSquad()} 
                >
                  x
                </button>
                <Dropdown
                  selectedItem={this.state.selectedSquad}
                  onSelect={(selectedSquad) => {
                    this.setState({ selectedSquad });
                    opportunity.squad = selectedSquad;
                    this.updateSquad(selectedSquad._id, opportunity._id);
                  }}
                  downshiftProps={{
                    itemToString: (item: any) => item && item.name,
                  }}
                >
                  <Field>
                    <Select className="u-semibold u-fs-sm">
                      {this.state.selectedSquad
                        ? this.state.selectedSquad.name
                        : undefined}
                    </Select>
                  </Field>
                  <Menu className="u-fs-sm">
                    {this.state.squads.map((option: any) => (
                      <Item key={option._id} value={option}>
                        {option.name}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
              </div>
              
              <div className="col-md-6 col-sm-12">
                <label className="mr-2 u-fs-sm">{t("RESPONSÁVEL")}: </label>
                <button 
                  className="cancel-modal-button"
                  onClick={() => this.clearOppResponsible()} 
                >
                  x
                </button>
                <Dropdown
                  selectedItem={this.state.selectedResp}
                  onSelect={(selectedResp) => {
                    this.setState({ selectedResp });
                    this.updateResponsable(selectedResp._id, opportunity._id);
                  }}
                  downshiftProps={{
                    itemToString: (item: any) => item && item.displayName,
                  }}
                >
                  <Field>
                    <Select className="u-semibold u-fs-sm">
                      {this.state.selectedResp?.displayName}
                    </Select>
                  </Field>
                  <Menu className="u-fs-sm">
                    {this.state.responsables.map((option: any) => (
                      <Item key={option._id} value={option}>
                        {option.displayName}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12 col-sm-12">
                <PeriodsComponent opportunity={opportunity}></PeriodsComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CardMajor);