import { Col } from "@zendeskgarden/react-grid";
import { FooterItem } from "@zendeskgarden/react-modals";
import Modal from "react-bootstrap/Modal";
import { Button, Row } from "react-bootstrap";
import { Tooltip } from "@zendeskgarden/react-tooltips";
import styled from "styled-components";
import { Component } from "react";
import { AlertService } from "../../../../services/alert.service";
import OpportunityService from "../../../../services/opportunity.service";
import UserService from "../../../../services/user.service";
import { Span, LG } from "@zendeskgarden/react-typography";
import { ReactComponent as InfoIcon } from "../../../../images/icons/info.svg";
import TranslationService, {
  t,
} from "../../../../translations/translation.service";
import { AuthenticationService } from "../../../../services/auth.service";
import { Stepper } from "@zendeskgarden/react-accordions";
import { dateHelper } from "../../../../util/date";
import { Datepicker } from "@zendeskgarden/react-datepickers";
import {
  Field,
  Label,
  Radio,
  Hint,
  Input,
  Range,
  Textarea,
  Message,
} from "@zendeskgarden/react-forms";
import Loading from "../../../loading";
import ProfessionalAvatar from "../avatar-info";
import styles from "./index.module.scss";
import OpportunityCard from "../../../home/opportunity-card";
import { AssessmentType } from "../../candidate-card/card/types";

const dateFormatter = dateHelper("pt-BR");

const StyledContainer = styled.div`
  margin: ${(p) => p.theme.space.md} 0 0 0;
`;

// const StyledField = styled(Field)`
//   margin-top: ${(p) => p.theme.space.xs};
// `;

class ProfessionalCard extends Component<{
  user: any;
  history: any;
  evaluations: any;
  onCompatibleClick(hideProfessionals: boolean, professionalId: string): void;
}> {
  state = {
    openCompatibleModal: false,
    compatibleOpportunities: [] as any[],
    inscribeModal: false,
    currentStep: 0,
    avaliationModal: false,
    sendEvaluationModal: false,
    evaluationsToSend: [] as any,
    selectedId: "",
    opportunities: [] as any[],
    avaliation: {
      avaliacao: {} as any,
      resultado: 0,
      user: AuthenticationService.getUser(),
      conteudo: "",
      created: new Date(),
    },
    loading: false,
    currentLang: TranslationService.getCurrentLanguage,
    processType: "",
    alertText: "",
    alertModal: false,
    alertModalGradeZero: false,
    registerModal: false,
  };

  constructor(props: any) {
    super(props);
    this.allSteps = this.allSteps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitEvaluation = this.submitEvaluation.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    // this.sendEvaluationToUser = this.sendEvaluationToUser.bind(this);
  }

  onNext = () => this.setState({ currentStep: this.state.currentStep + 1 });
  onBack = () => this.setState({ currentStep: this.state.currentStep - 1 });

  handleChange(event: any) {
    this.setState({
      avaliation: { ...this.state.avaliation, conteudo: event.target.value },
    });
  }

  sendEmailToProfessional = async (email: string, activeLanguage: string) => {
    try {
      await AuthenticationService.resendSignupMail(email, activeLanguage);
      AlertService.success("E-mail enviado com sucesso!");
      this.setState({ registerModal: false });
    } catch (err: any) {
      console.log("Erro ao enviar email ERRO: ", err);
      AlertService.error("Erro ao enviar email");
      this.setState({ registerModal: false });
    }
  }

  async submitEvaluation() {
    this.setState({ loading: true });
    try {
      await UserService.registerEvaluation(
        this.props.user._id,
        this.state.avaliation
      );
      this.setState({
        avaliation: {
          ...this.state.avaliation,
          avaliacao: {},
          resultado: 0,
          created: new Date(),
          conteudo: "",
        },
        loading: false,
        avaliationModal: false,
        currentStep: 0,
        alertModalGradeZero: false
      });
      AlertService.success(t("Avaliação registrada com sucesso."));
    } catch (err) {
      console.error(err);
      AlertService.error("Ocorreu algum erro!");
      this.setState({ loading: false, avaliationModal: false, alertModalGradeZero: false });
    }
  }

  allSteps = () => [
    {
      content: (
        <>
          {this.props.evaluations.map((item: any) => {
            if(item.name === AssessmentType.TechnicalAdvice) {
              return(<></>)
            }

            return (
              <Field className="mb-4">
                <Radio
                  disabled={item.name === "Desenvolvedor Java"}
                  name="hint-example"
                  value={item.name}
                  checked={this.state.avaliation.avaliacao.name === item.name}
                  onChange={() =>
                    this.setState({
                      avaliation: {
                        ...this.state.avaliation,
                        avaliacao: item,
                      },
                    })
                  }
                >
                  <Label>{t(item.name)}</Label>
                  {
                    item.name === "Desenvolvedor Java" &&
                      <Hint>
                        <Message validation="warning">
                          {t("Avaliação Indisponível")}
                        </Message>
                      </Hint>
                  }
                  {
                    item.name !== "Desenvolvedor Java" &&
                    <Hint>
                      {t(
                        "O usuário teve uma avaliação acima da média nessa categoria."
                      )}
                    </Hint>
                  }
                </Radio>
              </Field>
            );
          })}
        </>
      ),
    },
    {
      content: (
        <>
          <Row className="mb-4">
            <Col textAlign="center">
              <Field className="mb-4">
                <LG>
                  <Span isBold>Avaliação escolhida:</Span>{" "}
                  <Span>{this.state.avaliation.avaliacao.name}</Span>{" "}
                </LG>
              </Field>
            </Col>
          </Row>

          <Field className="mb-4">
            <Label>{t("Data da avaliação")}</Label>
            <Datepicker
              value={this.state.avaliation.created}
              onChange={(date) =>
                this.setState({
                  avaliation: { ...this.state.avaliation, created: date },
                })
              }
              formatDate={(date) => dateFormatter.format(date)}
              isCompact
            >
              <Input isCompact />
            </Datepicker>
          </Field>
          <Field className="mb-4">
            <Label>Nota</Label>
            <Hint>
              O usuário tem nota {this.state.avaliation.resultado} nessa
              avaliação.
            </Hint>
            <Range
              step={1}
              max={10}
              value={this.state.avaliation.resultado}
              onChange={(e) =>
                this.setState({
                  avaliation: {
                    ...this.state.avaliation,
                    resultado: e.target.value,
                  },
                })
              }
            />
          </Field>
          <Field>
            <Label>Avaliação</Label>
            <Textarea
              value={this.state.avaliation.conteudo}
              onChange={this.handleChange}
              minRows={2}
              maxRows={12}
            />
          </Field>
        </>
      ),
    },
  ];

  isEvaluationStar = (evaluation: any[]): boolean => {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado >= 8) {
          result = true;
        }
      });
    }
    return result;
  };

  isEvaluationUpdated(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) > currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationOutOfDate(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      let currenDate = new Date();
      currenDate.setFullYear(currenDate.getFullYear() - 1);
      evaluation.forEach((item: any) => {
        if (new Date(item.created) <= currenDate) {
          result = true;
        }
      });
    }
    return result;
  }

  isEvaluationNegative(evaluation: any) {
    let result = false;
    if (evaluation && evaluation.length > 0) {
      evaluation.forEach((item: any) => {
        if (item.resultado === 0) {
          result = true;
        }
      });
    }
    return result;
  }

  getOpportunities(processType: string) {
    this.setState({ loading: true, processType }, async () => {
      let opps = (await OpportunityService.getActiveOpportunities()).data;
      this.setState({
        inscribeModal: true,
        opportunities: opps,
        loading: false,
      });
    });
  }

  handleCheckbox(e: any, avaliation: any) {
    if (e.target.checked) {
      this.setState((state: any) => {
        const evaluationsToSend = state.evaluationsToSend.concat(avaliation);
        return {
          evaluationsToSend,
        };
      });
    } else {
      this.setState((state: any) => {
        const evaluationsToSend = state.evaluationsToSend.filter(
          (item: any) => item.name !== avaliation.name
        );
        return {
          evaluationsToSend,
        };
      });
    }
  }
  // teste Java descontinuado no Enter
  // sendEvaluationToUser() {
  //   this.setState({ loading: true });
  //   try {
  //     const obj = {
  //       professionalName: this.props.user.displayName,
  //       professionalEmail: this.props.user.email,
  //       evaluation: this.state.evaluationsToSend,
  //       activeLanguage: TranslationService.getCurrentLanguage(),
  //     };
  //     UserService.sendEvaluation(obj);
  //     AlertService.success(t("Avaliação enviada com sucesso."));
  //     this.setState({ loading: false, evaluationsToSend: [] });
  //   } catch (err) {
  //     AlertService.success(t("Não foi possível enviar essa avaliação."));
  //     this.setState({ loading: false });
  //   } finally {
  //     this.setState({ sendEvaluationModal: false });
  //   }
  // }

  async becomeCandidateByRh() {
    this.setState({ loading: true }, async () => {
      try {
        let triageRecruitmentOptions;

        if (
          this.props.user.steps &&
          this.props.user.steps.length &&
          this.props.user.capture &&
          this.props.user.source
        ) {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: this.props.user.capture,
            source: this.props.user.source,
          };
        } else {
          triageRecruitmentOptions = {
            details: "",
            date: new Date(Date.now()),
            capture: "",
            source: "",
          };
        }

        const obj = {
          activeLanguage: this.state.currentLang(),
          _usuario: { _id: this.props.user._id },
          recruiter: AuthenticationService.getUser(),
          triageRecruitmentOptions: triageRecruitmentOptions,
          conversionType: this.state.processType,
          byRH: true,
        };
        const resp = await OpportunityService.becomeCandidateByRh(
          this.state.selectedId,
          obj
        );
        this.setState({ inscribeModal: false, loading: false }, () => {
          resp.status === 200
          ? AlertService.warn(t(resp.data.message))
          : AlertService.success(t(resp.data.message));
        });
      } catch (error) {
        console.error(error);
        AlertService.error(error);
        this.setState({ loading: false });
      }
    });
  }

  async getCompatibleOpportunities(id: string) {
    if(this.state.openCompatibleModal) {
      this.setState({
        openCompatibleModal: false,
      });


      this.props.onCompatibleClick(false, "");

      return;
    }

    this.setState({
      loading: true
    });

    const { data: opportunities } = await UserService.getCompatibleOpportunities(id);

    if (!opportunities.length) {
      this.setState({
        alertText: "Sem oportunidades de alta compatibilidade. (+ 60% de afinidade)",
        alertModal: true,
        loading: false
      });

      return;
    }

    this.setState({
      loading: false,
      openCompatibleModal: true,
      compatibleOpportunities: opportunities
    });


    this.props.onCompatibleClick(true, this.props.user._id)
  }

  cleanPercentage = (percentage: number) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0;
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
  }

  searchOpportunities = async (search: string) => {
    let opps = this.state.opportunities;
    this.setState({
      opportunities: [...opps.filter((opp: any) =>
        opp.titulo.toLowerCase().includes(search.toLowerCase())
      )]
    });
  }

  resetOpportunities = async () => {
    this.setState({ loading: true });
    let opps = (await OpportunityService.getActiveOpportunities()).data;
    this.setState({
      opportunities: opps,
      loading: false
    });
  }

  render() {
    const { user } = this.props;
    // const emailIndex = user.notificationsSettings.channels.findIndex((channel: any) => channel.name === "E-mail");

    // const hasEmailContactPermission =
    //   emailIndex !== -1 &&
    //   user.notificationsSettings.isActive &&
    //   user.notificationsSettings.channels[emailIndex]?.isActive;

    return (
      <Col sm={12} md={this.state.openCompatibleModal ? 12 : 6} lg={this.state.openCompatibleModal ? 12 : 4} xl={this.state.openCompatibleModal ? 12 : 3} key={user.id}>
        <Loading show={this.state.loading} />
        <Row>
          <Col lg={this.state.openCompatibleModal ? 4 : 12} xl={this.state.openCompatibleModal ? 3 : 12}>
            <div className={user.eluminer.status ? styles["user-card-eluminer"] : styles["user-card"]}>
              <ProfessionalAvatar user={user}></ProfessionalAvatar>
              <div className="w-100">
                <Button
                  className={styles["button-gray"]}
                  onClick={() =>
                    window.open(`/professional/${this.props.user._id}`, "_blank")
                  }
                >
                  {t("Ver currículo")}
                </Button>
                <Button
                  onClick={() => this.setState({ sendEvaluationModal: true })}
                  className={styles["button-gray"]}
                  disabled
                >
                  {t("Enviar avaliação")}
                </Button>
                <Button
                  className={styles["button-orange"]}
                  onClick={() => this.getOpportunities("inscribe")}
                >
                  {t("Inscrever")}
                </Button>
                <Button
                  className={styles["button-orange"]}
                  onClick={() => this.getOpportunities("candidate")}
                >
                  {t("Tornar candidato")}
                </Button>
                {
                  this.props.user.registerRh && (
                    <Button
                      onClick={() => this.setState({ registerModal: true })}
                      className={styles["button-white"]}
                    >
                      {t("Confirmar cadastro via e-mail")}
                    </Button>
                  )
                }
                <Button
                  onClick={() => this.setState({ avaliationModal: true })}
                  className={styles["button-white"]}
                >
                  {t("Registrar avaliação")}
                </Button>
                <Button
                  onClick={() => {
                    this.getCompatibleOpportunities(this.props.user._id);
                  }}
                  className={styles["button-white"]}
                >
                  {t("Ver oportunidades compatíveis")}
                </Button>
              </div>
              {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
              {
                // MODAL DE INSCRIÇÃO
                this.state.inscribeModal && (
                  <Modal
                    scrollable
                    show={this.state.inscribeModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ inscribeModal: false })}
                  >
                    <Modal.Header>
                      <Row>
                        <Col size={12} className="mb-2">
                          {t("Em que oportunidade você quer inscrever @ profissional?")}
                          <button onClick={() => this.setState({ inscribeModal: false })} type="button" className="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </Col>
                        <Col size={12}>
                          <h4 style={{ color: '#ed961c' }}>{t("Lembre-se de que el@ será notificad@ por e-mail.")}</h4>
                        </Col>
                      </Row>
                    </Modal.Header>
                    <Modal.Body className={styles["style-scroll-bar-find"]}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Buscar oportunidade...")}
                        onChange={(e) => this.searchOpportunities(e.target.value)}
                      />
                      <br/>
                      <Button
                        variant="outline-warning"
                        style={{ borderRadius: "20px", padding: "3px 25px", marginLeft: "80%" }}
                        onClick={() => this.resetOpportunities()}
                      >
                        Limpar busca
                      </Button>
                      <p className="u-semibold mt-2">{t("Oportunidades")}</p>
                      {this.state.opportunities.map((opp) => (
                        <Button
                          key={opp.id}
                          className={
                            (this.state.selectedId === opp._id
                              ? styles["modal-button-selected"]
                              : styles["modal-button-opp"]) + " mt-2 qualquer"
                          }
                          onClick={() => this.setState({ selectedId: opp._id })}
                        >
                          {opp.titulo}
                          <Tooltip
                            size="small"
                            type="light"
                            content={`${t("Recrutador(a)")}: ${
                              opp.user?.displayName
                            }`}
                            placement="top-end"
                          >
                            <InfoIcon className={styles["info"]} />
                          </Tooltip>
                        </Button>
                      ))}
                    </Modal.Body>
                    <Modal.Footer>
                      <FooterItem>
                        <Button
                          disabled={this.state.selectedId === ""}
                          variant="outline-warning"
                          onClick={() => this.becomeCandidateByRh()}
                          style={{ borderRadius: "20px", padding: "3px 25px" }}
                        >
                          {t("Salvar")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }
              {
                // MODAL DE ENVIAR AVALIAÇÃO
                // Teste Java descontinuado no Enter
                // this.state.sendEvaluationModal && (
                //   <Modal
                //     show={this.state.sendEvaluationModal}
                //     size="lg"
                //     aria-labelledby="contained-modal-title-vcenter"
                //     centered
                //     onHide={() =>
                //       this.setState({
                //         sendEvaluationModal: false,
                //         evaluationsToSend: [],
                //       })
                //     }
                //   >
                //     <Modal.Header>
                //       {t("Escolha a avaliação a ser enviada para o profissional.")}
                //     </Modal.Header>
                //     <Modal.Body>
                //       <Row>
                //         <Col size="auto">
                //           {this.props.evaluations.map((evaluation: any) => {
                //             if(evaluation.name === AssessmentType.TechnicalAdvice) {
                //               return (<></>)
                //             }

                //             return (
                //               <StyledField className="mb-4">
                //                 <Checkbox
                //                   disabled={
                //                     evaluation.name === "Parecer Comportamental"
                //                   }
                //                   onChange={(e) =>
                //                     this.handleCheckbox(e, evaluation)
                //                   }
                //                 >
                //                   <Label>{evaluation.name}</Label>
                //                 </Checkbox>
                //                 {evaluation.name === "Parecer Comportamental" && (
                //                   <Message validation="warning">
                //                     Esta avaliação não possui um link para ser
                //                     enviado aos profissionais.
                //                   </Message>
                //                 )}
                //               </StyledField>
                //             );
                //           })}
                //         </Col>
                //       </Row>
                //     </Modal.Body>
                //     <Modal.Footer>
                //       <FooterItem>
                //         <Button
                //           onClick={this.sendEvaluationToUser}
                //           variant="outline-warning"
                //           style={{ borderRadius: "20px", padding: "3px 25px" }}
                //         >
                //           {t("Enviar")}
                //         </Button>
                //       </FooterItem>
                //     </Modal.Footer>
                //   </Modal>
                // )
              }
              {
                //MODAL PARA CONFIRMAÇÂO DE PARECER NOTA 0
                this.state.alertModalGradeZero && (
                  <Modal
                    show={this.state.alertModalGradeZero}
                    centered
                    onHide={() => this.setState({ alertModalGradeZero: false })}
                  >
                    <Modal.Header
                      className="text-center"
                      style={{ color: '#4d4c4c'}}
                    >
                      <h3>{t(" Atenção! Nota de Parecer Comportamental 0")}</h3>
                    </Modal.Header>
                    <Modal.Body
                      className="text-center"
                      style={{ color: '#4d4c4c'}}
                    >
                      <p>{t("Tem certeza que deseja aplicar nota zero?")}</p>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-between">
                      <button
                        className={styles["cancel-button-modal"]}
                        onClick={() => this.setState({ alertModalGradeZero: false })}
                      >
                        {t("Cancelar")}
                      </button>
                      <button
                        className={styles["confirm-button-modal"]}
                        onClick={this.submitEvaluation}
                      >
                        {t("Confirmar")}
                      </button>
                    </Modal.Footer>
                  </Modal>
                )
              }
              {
                // MODAL DE REGISTRAR AVALIAÇÃO
                this.state.avaliationModal && (
                  <Modal
                    show={this.state.avaliationModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ avaliationModal: false })}
                  >
                    <Modal.Header className="justify-content-center">
                      <Stepper activeIndex={this.state.currentStep} isHorizontal>
                        <Stepper.Step key="step-1">
                          <Stepper.Label>Escolha uma avaliação</Stepper.Label>
                        </Stepper.Step>
                        <Stepper.Step key="step-2">
                          <Stepper.Label>Escolha a nota</Stepper.Label>
                        </Stepper.Step>
                      </Stepper>
                    </Modal.Header>
                    <Modal.Body>
                      {this.allSteps().map(
                        (step, index) =>
                          index === this.state.currentStep && (
                            <StyledContainer key={index}>
                              {step.content}
                            </StyledContainer>
                          )
                      )}
                    </Modal.Body>
                    <Modal.Footer
                      className={
                        this.state.currentStep !== this.allSteps().length - 1
                          ? ""
                          : "justify-content-between"
                      }
                    >
                      {this.state.currentStep !== 0 && (
                        <FooterItem>
                          <Button
                            onClick={this.onBack}
                            className={styles["modal-button-submit"]}
                            variant="outline-warning"
                            style={{ padding: "3px 30px" }}
                          >
                            {t("Anterior")}
                          </Button>
                        </FooterItem>
                      )}
                      <FooterItem>
                        <Button
                          disabled={
                            Object.keys(this.state.avaliation.avaliacao).length ===
                            0
                          }
                          className={
                            this.state.currentStep !== this.allSteps().length - 1
                              ? styles["modal-button-next"]
                              : styles["modal-button-submit"]
                          }
                          onClick={
                            this.state.currentStep !== this.allSteps().length - 1
                              ? this.onNext
                              : this.state.avaliation.resultado === 0
                                ? () => this.setState({ alertModalGradeZero: true })
                                : this.submitEvaluation
                          }
                          style={{ padding: "3px 30px" }}
                        >
                          {this.state.currentStep !== this.allSteps().length - 1
                            ? t("Próximo")
                            : t("Finalizar")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }
                            {
                // MODAL DE INSCRIÇÃO
                this.state.alertModal && (
                  <Modal
                    show={this.state.alertModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ alertModal: false, alertText: "" })}
                  >
                    <Modal.Header>
                      {t("Usuario sem compatibilidade.")}
                    </Modal.Header>
                    <Modal.Body>
                      <p className="mt-2">{t(this.state.alertText)}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <FooterItem>
                        <Button
                          variant="outline-warning"
                          onClick={() => this.setState({ alertModal: false, alertText: "" })}
                          style={{ borderRadius: "20px", padding: "3px 25px" }}
                        >
                          {t("Ok")}
                        </Button>
                      </FooterItem>
                    </Modal.Footer>
                  </Modal>
                )
              }
            </div>
          </Col>
          {this.state.openCompatibleModal && (
            <Col sm={12} md={12} lg={8} xl={9}>
              {this.state.compatibleOpportunities.map((opp, index) => {
                const percentage = this.cleanPercentage(opp.candidatosCompativeis[0].nivelAderencia);
                const r = 30;
                const circ = 2 * Math.PI * r;
                const strokePct = ((100 - percentage) * circ) / 100;
                
                return (
                  <Row noGutters sm={12} md={12} lg={12} xl={12}>
                    <Col sm={12} md={2} lg={2} xl={1}>
                      <div className={`${styles["circle-progress"]}`} data-pct={percentage}>
                        <svg
                          viewBox="0 0 70 70"
                          className={styles["circle-progress-content"]}
                        >
                          <circle
                            className={styles["bar"]}
                            r={r}
                            cx={35}
                            cy={35}
                            fill="transparent"
                            strokeWidth="3"
                            strokeDasharray={circ}
                            strokeDashoffset={percentage ? strokePct : 0}
                            stroke= "#4ea829"
                          />
                        </svg>
                      </div>
                    </Col>
                    <Col sm={12} md={10} lg={10} xl={10} className={`${styles["opp-container"]}`}>
                      <OpportunityCard
                        id={opp._id}
                        index={index}
                        key={index}
                        title={opp.title}
                        geolocation={opp.geolocation}
                      />
                    </Col>
                  </Row>
                )
              })}
            </Col>
          )}
          {
            this.state.registerModal && (
              <Modal
                show={this.state.registerModal}
                onHide={() => this.setState({ registerModal: false })}
                size="sm"
                centered
              >
                <Modal.Header>
                  <Label>
                    Confirmação de cadastro
                  </Label>
                </Modal.Header>
                <Modal.Body>
                  Enviar e-mail de confirmação de cadastro?
                </Modal.Body>
                <Modal.Footer style={{display: 'flex', justifyContent: 'space-between'}}>
                  <button
                    className={styles['cancel-button-modal']}
                    onClick={() => this.setState({ registerModal: false })}
                  >
                    Cancelar
                  </button>
                  <button
                    className={styles['confirm-button-modal']}
                    onClick={() => this.sendEmailToProfessional(this.props.user.email, this.state.currentLang())}
                  >
                    Enviar
                  </button>
                </Modal.Footer>
              </Modal>
            )
          }
        </Row>
      </Col>
    );
  }
}

export default ProfessionalCard;
