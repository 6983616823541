import { AxiosResponse } from "axios";
import { SearchUsers } from "../pages/professional/find";
import service from "./api.service";
import { responsibleData } from "../model/opportunity.model";

class UserService {
  static find = (
    params: string[],
    qtdPerPage: number,
    pageId: number,
    pcdFilter: any,
    talentFilter: boolean,
    europeanUnionCitizenshipFilter: boolean,
    typeFilter: string,
  ): Promise<AxiosResponse<SearchUsers>> => {
    return service.get(
      `users/find/${params.join(",")}/${qtdPerPage}/${pageId}`,
      {
        params: {
          pcdFilter, talentFilter, europeanUnionCitizenshipFilter, typeFilter
        }
      }
      );
  };

  static getUserWithOpportunities = (id: string) => {
    return service.get(`users/user-with-opportunities/${id}`);
  };

  static getUserWithoutOpportunities = (id: string) => {
    return service.get(`users/user-without-opportunities/${id}`);
  }

  // Teste Java descontinuado no Enter
  // static sendEvaluation = (obj: any) => {
  //   return service.post(`userevaluation/user/send-evaluation-list`, obj);
  // }

  static registerEvaluation = (id: any, obj: any) => {
    return service.post(`userevaluation/${id}/registrar-avaliacao`, obj);
  }

  static updateUser = (obj: any) => {
    return service.put("users", obj);
  }

  static updatePreRegister = (obj: any) => {
    return service.put("users/pre-register", obj);
  }

  static getUser = (id: string) => {
    return service.get(`users/me/${id}`);
  };

  static getTalent = (id: string) => {
    return service.get(`users/talent/${id}`);
  };

  static getHighCompatiblesByTalent = (id: string) => {
    return service.get(`users/talent/high-compatibles-opportunities/${id}`);
  };

  static updateTalent = (id: string, body: any) => {
    return service.patch(`users/talent/${id}`, body);
  };

  static getStatusUserMatch = (id: string) => {
    return service.get(`users/${id}/get-status-vagas`);
  };

  static changePassword = (
    obj: {
      currentPassword: string,
      newPassword: string,
      verifyPassword: string
    }
    ) => {
    return service.post("users/password", obj);
  }
  
  static sendWelcomeMail = async (_id: string) => {
    const resp = await service.post('users/send-welcome-mail', { user: { _id } });
    return resp.data;
  };

  static deleteUser = () => {
    return service.delete("users");
  };

  static deletePreRegister = (userId: string) => {
    return service.delete(`users/${userId}/exclude-pre-register`);
  }

  static getTalents = (
      qtdPerPage: number, 
      pageId: number, 
      responsableFilter: any, 
      groupFilter: string, 
      sortFilterList: number, 
      keywordsList: string[],
      dateFilter: {
        startCalendarValue: string,
        endCalendarValue: string,
      },
    ) => {

    let params = []; 
    if (responsableFilter && responsableFilter._id && responsableFilter._id !== -1) {
      params.push(`responsable=${responsableFilter._id}`);
    }
    if (groupFilter) {
      params.push(`group=${groupFilter}`);
    }
    if (sortFilterList) {
      params.push(`sort=${sortFilterList}`);
    }
    if (keywordsList && keywordsList.length > 0) {
      const keywords = keywordsList.join('|');
      params.push(`keywords=${keywords}`);
    }
    if (dateFilter) {
      params.push(`dateStart=${dateFilter.startCalendarValue}`);
      params.push(`dateEnd=${dateFilter.endCalendarValue}`);
    }
    return service.get(`users/get-talents/${qtdPerPage}/${pageId}?${params.join('&')}`);
  }


  static getCompatibleOpportunities = (professionalId: string) => {
    return service.get("users/" + professionalId + "/get-vagas-compativeis");
  }

  static sendConfirmationEmail = (
    professionalId: string,
    opportunityId: string,
    opportunityTitle: string
  ) => {
    return service.post("users/resend-pre-register-email", { professionalId, opportunityId, opportunityTitle });
  }
  
  static getResponsables = () => {
    return service.get("profile/recruitment"); 
  }

  static changePcdStatus = (idUser: string, status: any) => {
    return service.put(`profile/changePcdState/${idUser}`, status);
  }

  static becomeProfessionalEluminer = (idUser: string): Promise<AxiosResponse<any>> => {
    return service.post(`profile/become-eluminer/${idUser}`);
  }

  static updateProfessionalEluminerStatus = (id: string, status: boolean): Promise<AxiosResponse<any>> => {
    return service.put(`profile/update-eluminer/${id}`, {status});
  }

  static updateCollaboratorExEluminer = (id: string, status: boolean): Promise<AxiosResponse<any>> =>{
    return service.put(`profile/update-ex-eluminer/${id}`, {status});
  };

  static updateIndicationUserStatus = (id: string, status: boolean): Promise<AxiosResponse<any>> =>{
    return service.put(`profile/indication-user/${id}`, {status});
  };

  static updateLocationInternationalStatus= (id: string, status: boolean): Promise<AxiosResponse<any>> =>{
    return service.put(`profile/location-international/${id}`, {status});
  };

  static getRecruiterEvaluation = (idUser: string, data: any): Promise<AxiosResponse<any>> => {
    return service.post(`users/recruiterEvaluations/${idUser}`, data)
  }

  static getPreRegisterReportList = (
    responsibleId: string, period: string, referenceYear: number
  ):Promise<AxiosResponse<responsibleData>> => {
    return service.get(`reports/preRegisterReport/${responsibleId}/${period}/${referenceYear}`)
  }

  static getOpportunitiesCreatedList = (
    responsibleId: string, period: string, referenceYear: number
  ): Promise<AxiosResponse<any>> => {
    return service.get(`reports/opportunitiesReport/${responsibleId}/${period}/${referenceYear}`)
  }

  static getProfessionalsByHRList = (
    responsibleId: string, period: string, referenceYear: number
  ): Promise<AxiosResponse<any>> => {
    return service.get(`reports/ProfessionalsRegisteredByHR/${responsibleId}/${period}/${referenceYear}`)
  }

  static setPreRegisterMade = (payload: any): Promise<AxiosResponse<any>> => {
    return service.post(`users/preRegisterMade`, payload)
  }

  static sendReportEmailToClient = (payload: any): Promise<AxiosResponse<any>> => {
    return service.post('reports/sendReportEmail', payload);
  }

  static getPreRegisterByOpportunity = (opportunityId: string): Promise<AxiosResponse<any>> => {
    return service.get(`users/preRegisterMadeByOpportunity/${opportunityId}`);
  }

  static getPrivacyPolicy = (userId: string): Promise<AxiosResponse<any>> => {
    return service.get(`users/privacyPolicyAcceptance/${userId}`);
  }

  static setPrivacyPolicy = (userId: string, payload: object): Promise<AxiosResponse<any>> => {
    return service.post(`users/privacyPolicyAcceptance/${userId}`, payload);
  }

  static setPrivacyPolicySignupLocation = (userId: string, payload: object): Promise<AxiosResponse<any>> => {
    return service.post(`users/privacyPolicyAcceptance/signup-location/${userId}`, payload);
  }

  static updateEuropeanUnionCitizenship = (userId: string, status: boolean): Promise<AxiosResponse<any>> => {
    return service.post(`profile/updateEuropeanUnionCitizenship/${userId}`, {status});
  }

  static addEventToFeed = (userId: string, payload: object): Promise<AxiosResponse<any>> => {
    return service.post(`feed/${userId}`, payload);
  }
  
  static getUserFeed = (userId: string): Promise<AxiosResponse<any>> => {
    return service.get(`feed/${userId}`);
  }

  static editEventByRh = (userID: string, eventId: string, payload: object): Promise<AxiosResponse<any>> => {
    return service.put(`feed/${userID}/${eventId}`, payload);
  }
}

export default UserService;
